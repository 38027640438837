



import * as React from "react"
import Layout from "../components/layout"


const WorkPage = () => {
    return (
        <Layout pageTitle="Past Projects">
            <p>As both restoration specialists and general contractor since 1979, we have done extensive residential and commercial work throughout the Napa-Sonoma region and beyond.  </p>

            <p>In addition to many architecturally significant residential projects, the following are just some of the notable commercial projects with which we have been involved:</p>
            <ul>
                <li>★Opus One Winery </li>
                <li>★Carneros Inn Resort and Spa</li>
                <li>★The Lodge at Indian Springs </li>
                <li>★Sonoma Art Museum</li>
                <li>★Copola Niebaum Estate Restoration</li>
                <li>★City Winery / Napa Valley Opera House (Owners Rep)</li>
                <li>★Napa Valley Hospice </li>
                <li>★Di Rosa Preserve </li>
                <li>★St. Supery Winery</li>
                <li>★Embarcadero Hotel </li>
                <li>★Redwood Credit Union</li>
                <li>★Stryker Sonoma Winery </li>
                <li>★MacArthur Place Resort and Spa   </li>
                <li>★Swanson Winery </li>
                <li>★Blue Oak School </li>
                <li>★Starmont Winery </li>
                <li>★River Terrace Inn </li>
                <li>★St. Helena Railroad Depot </li>
                <li>★Corison Winery </li>
                <li>★Schramsberg Riddling Buildings </li>
                <li>★Nicholson Ranch Winery </li>
                <li>★Justin Sienna Library</li>
                <li>★Blue Oak Middle School </li>
                <li>★Presentation School</li>
                <li>★Clark Hall Biology Building at Pacific Union College </li>
                <li>★Oakland Raiders Training Facility </li>
                <li>★Wolfe Center </li>
                <li>★River Inn </li>
                <li>★Sausalito Woman’s Club</li>
                <li>*Bungalow 313</li>
                <li>*Alma Spreckels Hunting and Fishing Lodge</li>
                <li>*Chaix Residence</li>
                <li>*1890 Cooperage</li>
                <li>*Adler Mill- Private Residence</li>

            </ul>

        </Layout>

    )
}

export default WorkPage
